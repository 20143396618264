label {
  padding-right:20px
}

input.affinityInput{
    width: 50px;
}

input.networkIdInput{
    width: 80px;
} 

form#search {
    text-align: center;
}

div#editor {
  margin-left: auto;
  margin-right: auto;
  width: 80%;
}

div#editorElement {
  border-style: solid;
  margin: 30px;
}

div.tokenProblem {
  color: red;
  margin: 5px;
}

td.tokenProblem {
  width: 200px;
  height: 30px;
  background: #ffffff
}

tr#bssToken:nth-child(even) {
    background: #e6e6e6
}

tr#bssToken:nth-child(odd) {
    background: #bbbbbb
}

tr#venueToken:nth-child(even) {
    background: #e6e6e6
}

tr#venueToken:nth-child(odd) {
    background: #bbbbbb
}


tr#bssToken:nth-child(even) {
    background: #e6e6e6
}

tr#bssToken:nth-child(odd) {
    background: #bbbbbb
}

table#venueData {
    margin-left: auto;
    margin-right: auto;
}

img#bouncing-poi {
  margin-left: auto;
  margin-right: auto;
  height: 50px;
}

.bounce {
    -webkit-animation-name: bounce;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-delay: 0;
    -webkit-animation-duration: 1s;
    -webkit-timing-function: ease-out;
    
    animation-name: bounce;
    animation-iteration-count: infinite;
    animation-delay: 0;
    animation-duration: 1s;
    timing-function: ease-out;
}

@-webkit-keyframes bounce {
    from { height: 50px; }
    5% { height: 51px; }
    10% { height: 52px; }
    15% { height: 53px; }
    20% { height: 54px; }
    25% { height: 55px; }
    30% { height: 56px; }
    35% { height: 57px; }
    40% { height: 58px; }
    45% { height: 59px; }
    50% { height: 60px; }
    55% { height: 59px; }
    60% { height: 58px; }
    65% { height: 57px; }
    70% { height: 56px; }
    75% { height: 55px; }
    80% { height: 54px; }
    85% { height: 53px; }
    90% { height: 52px; }
    95% { height: 51px; }
    100% { height: 50px; }
}

div#bouncing-poi {
  text-align: center;
  margin: auto;
  padding: 100px;
  vertical-align: top;
}



