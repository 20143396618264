.duplicateVenueToken:after {
  content: "\2718"
}

.duplicateVenueToken {
  color: red
}

.uniqueVenueToken:after {
  content: "\2714"
}
.uniqueVenueToken {
  color: green
}

input.networkIdInput {
   width:200px;
}
