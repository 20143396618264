body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

label {
  padding-right:20px
}

input.affinityInput{
    width: 50px;
}

input.networkIdInput{
    width: 80px;
} 

form#search {
    text-align: center;
}

div#editor {
  margin-left: auto;
  margin-right: auto;
  width: 80%;
}

div#editorElement {
  border-style: solid;
  margin: 30px;
}

div.tokenProblem {
  color: red;
  margin: 5px;
}

td.tokenProblem {
  width: 200px;
  height: 30px;
  background: #ffffff
}

tr#bssToken:nth-child(even) {
    background: #e6e6e6
}

tr#bssToken:nth-child(odd) {
    background: #bbbbbb
}

tr#venueToken:nth-child(even) {
    background: #e6e6e6
}

tr#venueToken:nth-child(odd) {
    background: #bbbbbb
}


tr#bssToken:nth-child(even) {
    background: #e6e6e6
}

tr#bssToken:nth-child(odd) {
    background: #bbbbbb
}

table#venueData {
    margin-left: auto;
    margin-right: auto;
}

img#bouncing-poi {
  margin-left: auto;
  margin-right: auto;
  height: 50px;
}

.bounce {
    -webkit-animation-name: bounce;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-delay: 0;
    -webkit-animation-duration: 1s;
    -webkit-timing-function: ease-out;
    
    animation-name: bounce;
    animation-iteration-count: infinite;
    animation-delay: 0;
    animation-duration: 1s;
    timing-function: ease-out;
}

@-webkit-keyframes bounce {
    from { height: 50px; }
    5% { height: 51px; }
    10% { height: 52px; }
    15% { height: 53px; }
    20% { height: 54px; }
    25% { height: 55px; }
    30% { height: 56px; }
    35% { height: 57px; }
    40% { height: 58px; }
    45% { height: 59px; }
    50% { height: 60px; }
    55% { height: 59px; }
    60% { height: 58px; }
    65% { height: 57px; }
    70% { height: 56px; }
    75% { height: 55px; }
    80% { height: 54px; }
    85% { height: 53px; }
    90% { height: 52px; }
    95% { height: 51px; }
    100% { height: 50px; }
}

div#bouncing-poi {
  text-align: center;
  margin: auto;
  padding: 100px;
  vertical-align: top;
}




/* Example Styles for React Tags*/
div.ReactTags__tags {
    position: relative;
}

/* Styles for the input */
div.ReactTags__tagInput {
    width: 200px;
    border-radius: 2px;
    display: inline-block;
}
div.ReactTags__tagInput input.ReactTags__tagInputField,
div.ReactTags__tagInput input.ReactTags__tagInputField:focus {
    height: 31px;
    margin: 0;
    font-size: 12px;
    width: 100%;
    border: 1px solid #eee;
}

/* Styles for selected tags */
div.ReactTags__selected span.ReactTags__tag {
    border: 1px solid #ddd;
    background: #eee;
    font-size: 12px;
    display: inline-block;
    padding: 5px;
    margin: 0 5px;
    border-radius: 2px;
}
div.ReactTags__selected a.ReactTags__remove {
    color: #aaa;
    margin-left: 5px;
    cursor: pointer;
}

/* Styles for suggestions */
div.ReactTags__suggestions {
    position: absolute;
}
div.ReactTags__suggestions ul {
    list-style-type: none;
    box-shadow: .05em .01em .5em rgba(0,0,0,.2);
    background: white;
    width: 200px;
}
div.ReactTags__suggestions li {
    border-bottom: 1px solid #ddd;
    padding: 5px 10px;
    margin: 0;
}
div.ReactTags__suggestions li mark {
    text-decoration: underline;
    background: none;
    font-weight: 600;
}
div.ReactTags__suggestions ul li.ReactTags__activeSuggestion {
    background: #b7cfe0;
    cursor: pointer;
}

.invalidInput:before {
  content: "\2718"
}

.invalidInput {
  color: red
}

.validInput:before {
  content: "\2714"
}
.validInput {
  color: green
}




.duplicateVenueToken:after {
  content: "\2718"
}

.duplicateVenueToken {
  color: red
}

.uniqueVenueToken:after {
  content: "\2714"
}
.uniqueVenueToken {
  color: green
}

input.networkIdInput {
   width:200px;
}

