.invalidInput:before {
  content: "\2718"
}

.invalidInput {
  color: red
}

.validInput:before {
  content: "\2714"
}
.validInput {
  color: green
}



